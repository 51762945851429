import { SearchFilter } from "src/components/Filter/SearchFilter"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  Ellipsis,
  FilterWrapper,
  IdWrapper,
  ParadiseLayout,
  ParadiseTitle,
  PillsWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { parseParadiseHomeSortField } from "src/data/paradise/paradiseHomes/logic/paradiseHome"
import { useFetchParadiseHomes } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { IParadiseHome } from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { colorsLegacy } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import InfoIcon from "src/ui/icons/info.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"
import { formatUtcDate } from "src/utils/l10n"

const LIMIT = 50

const tableColumns: TableColumn<IParadiseHome>[] = [
  {
    value: "home_id",
    label: "Home id",
    disabled: true,
    columnWidth: "min-content",
    disableClickPropagation: true,
    renderCell: (home) => (
      <div>
        <IdWrapper>
          <InternalLink to={Routes.ParadiseHome.location(home.id)}>
            {home.id}
          </InternalLink>
          {home.deleted && (
            <MTooltip title="Deleted">
              <InfoIcon
                width={12}
                fill={colorsLegacy.systemEmergencyForeground}
              />
            </MTooltip>
          )}
        </IdWrapper>
      </div>
    ),
  },
  {
    value: "name",
    label: "Name",
    disabled: true,
    columnWidth: 300,
    enableSort: true,
    renderCell: (home) => <Ellipsis>{home.name}</Ellipsis>,
  },
  {
    value: "created_at",
    label: "Created at",
    columnWidth: "min-content",
    enableSort: true,
    renderCell: (home) => (
      <div>{formatUtcDate({ date: home.created_at, excludeTime: true })}</div>
    ),
  },
  {
    value: "created_by",
    label: "Created by",
    columnWidth: "min-content",
    disableClickPropagation: true,
    renderCell: (home) => (
      <div>
        <InternalLink
          to={Routes.ParadiseUser.location(home.created_by).pathname}
        >
          {home.created_by}
        </InternalLink>
      </div>
    ),
  },
  {
    value: "deleted_at",
    label: "Deleted at",
    columnWidth: "min-content",
    enableSort: true,
    renderCell: (home) => (
      <div>
        {home.deleted_at ? formatUtcDate({ date: home.deleted_at }) : "-"}
      </div>
    ),
  },
  {
    value: "owner_id",
    label: "Owner id",
    columnWidth: "min-content",
    disableClickPropagation: true,
    renderCell: (home) => (
      <div>
        <InternalLink to={Routes.ParadiseUser.location(home.owner.id).pathname}>
          {home.owner.id}
        </InternalLink>
      </div>
    ),
  },
  {
    value: "tags",
    label: "Tags",
    columnWidth: "min-content",
    renderCell: (home) => (
      <PillsWrapper $noWrap>
        {home.tags.map((tag, i) => (
          <MBadge key={i} size="x-small">
            {tag}
          </MBadge>
        ))}
      </PillsWrapper>
    ),
  },
  {
    value: "timezone",
    label: "Timezone",
    columnWidth: "auto",
    renderCell: (home) => <div>{home.timezone}</div>,
  },
]

export function ParadiseHomes() {
  const { navigate } = useRouter()

  const { limit, offset, setOffset } = useUrlPager({ initialLimit: LIMIT })

  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "home_id",
        type: "string",
      },
      {
        key: "name",
        type: "string",
      },
      {
        key: "tag",
        type: "string",
      },
    ],
  })

  const { sortValue, setSortValue } = useSortParam()

  // If the user has filtered we want to show deleted homes aswell, otherwise just active homes is fetched
  const hasFiltered = Object.values(filter).some((f) => !!f)

  const fetchHomes = useFetchParadiseHomes({
    filter: {
      limit: limit,
      offset: offset,
      home_id: filter.home_id || undefined,
      name: filter.name || undefined,
      tag: filter.tag || undefined,
      sort: parseParadiseHomeSortField(sortValue?.id)
        ? sortValue?.id
        : undefined,
      sort_by: sortValue?.order,
      deleted: hasFiltered ? undefined : false,
    },
    options: {
      keepPreviousData: true,
    },
  })
  const homes = fetchHomes.data?.homes

  const {
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    updateColumnVisibility,
    templateColumns,
  } = useTableColumns<IParadiseHome>({
    columns: tableColumns,
    data: homes,
    options: { localStorageKey: "minut.paradise.homes.table" },
    sort: sortValue,
    onSortChange: setSortValue,
  })

  function handleFilterChange(filterKey: keyof typeof filter, value: string) {
    setOffset(0)
    setFilter(filterKey, value)
  }

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Homes
            {fetchHomes.isSuccess && (
              <MBadge color="info">
                Total: {fetchHomes.data?.total_count}
              </MBadge>
            )}
          </ParadiseTitle>
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <SearchFilter
            initialValue={filter.home_id ?? undefined}
            onChange={(v) => handleFilterChange("home_id", v)}
            placeholder="Search by id"
          />
          <SearchFilter
            initialValue={filter.name ?? undefined}
            onChange={(v) => handleFilterChange("name", v)}
            placeholder="Search by name"
          />
          <SearchFilter
            initialValue={filter.tag ?? undefined}
            onChange={(v) => handleFilterChange("tag", v)}
            placeholder="Search by tag"
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            updateColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>

      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (homes) {
            // @ts-expect-error: noUncheckedIndexedAccess
            navigate(Routes.ParadiseHome.location(homes[index].id))
          }
        }}
        error={{
          hasError: fetchHomes.isError,
          title: fetchHomes.error?.message,
        }}
      />

      {fetchHomes.data && (
        <ParadisePager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomes.data.total_count}
        />
      )}
    </ParadiseLayout>
  )
}
